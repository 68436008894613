body, h1 {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

em {
  background: #0ff;
  font-style: normal;
}

.compLogo {
  float: left;
  margin-bottom: 10px;
}

.header {
  color: #fff;
  background-color: #000;
  align-items: center;
  min-height: 50px;
  margin-bottom: 1rem;
  padding: .5rem 1rem;
  display: flex;
}

.header .logo {
  width: 140px;
  margin-right: 20px;
}

.header .gh-btn {
  float: right;
  position: absolute;
  right: 20px;
}

.element {
  float: left;
  width: 50%;
  overflow-clip-margin: unset !important;
  overflow: hidden !important;
}

.info {
  float: right;
  width: 30%;
  position: relative;
}

.info img {
  width: 40px;
  padding: 0;
  border-radius: 20px !important;
}

.avatar {
  border: 1px solid #dfdfdf;
  width: 40px;
  clip: auto !important;
  clip-path: none !important;
  border-radius: 30px !important;
  padding: 2px !important;
  overflow: hidden !important;
}

.header a {
  color: #fff;
  text-decoration: none;
}

.header-title {
  font-size: 1.2rem;
  font-weight: normal;
}

.header-title:after {
  padding: 0 .5rem;
}

.header-subtitle {
  font-size: 1.2rem;
}

.crunch {
  width: 30px;
  margin: 2px;
  box-shadow: 2px 2px 4px #0003;
  clip: unset !important;
  clip-path: unset !important;
  overflow: hidden !important;
}

.crunch:hover {
  box-shadow: 2px 2px 4px #0003;
}

.filter-el {
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
}

.filter-el h4 {
  border-bottom: 1px solid #dfdfdf;
  margin-top: 0;
  padding: 4px;
}

.search-panel {
  display: flex;
}

.searchbox {
  margin-bottom: 2rem;
}

.pagination {
  text-align: center;
  margin: 2rem auto;
}

.ais-Hits-item {
  margin: 0;
  padding: 10px !important;
}

.ais-Hits-item:hover {
  background-color: #efefef !important;
}

.ais-Hits-item article {
  width: 100%;
  display: block;
  overflow: hidden !important;
}

.ais-Hits-item img {
  max-height: 125px;
  margin-right: 16px;
  overflow: hidden;
}

.modal-overlay {
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-content {
  background-color: #fff;
  border-radius: 5px;
  width: 500px;
  padding: 20px;
  position: relative;
}

.headerBtn {
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  top: 10px;
  right: 10px;
}

.avatar:hover {
  background-color: #fff;
  border: 1px solid #dfdfdf;
}

.ais-RefinementList-item {
  padding-left: 10px;
}

.ais-RefinementList-item--selected {
  background-color: #edffdc;
}

.ais-RefinementList-item--selected:hover {
  background-color: #b2ed78;
}

.ais-Stats-text {
  float: left;
  font-size: 10px;
  font-weight: light;
  display: block;
  position: relative;
}

h4 {
  font-size: 16px !important;
}

.avatar_img {
  border: 1px solid #000;
  border-radius: 5px;
  width: 20px;
  margin-right: 10px;
}

.avatar-btn {
  --bs-btn-padding-y: .25rem;
  --bs-btn-padding-x: .5rem;
  --bs-btn-font-size: .75rem;
  background-color: #fff;
  margin-bottom: 10px !important;
}

.avatar-btn:hover {
  color: #000;
  background-color: #dfdfdf;
}

.alertBox {
  background-color: #efefef;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  margin-bottom: 5px;
  padding: 20px;
}

.avatar-border {
  border-radius: 10px;
}
/*# sourceMappingURL=index.1c3b7569.css.map */
